// enable these three for the multistep form
import './vendor/jquery.min.js'
import './vendor/jquery.easing.min.js'
import multistep from './multi-step-form.js'

import validate from './vendor/jquery.validate.min.js'
import './vendor/jquery.viewportchecker.min.js'
import './vendor/fancybox.js'
import contentLoaded from './vendor/contentloaded.min.js'
//import accordion from './accordion.js'
import images from './images.js'
import phoneConcat from './phone-concatination.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import smoothScroll from './smooth-scroll.js'
import scrollTabs from './scroll-tabs.js'
import stickyHeader from './sticky-header.js'
import tabs from './tabs.js'
import timer from './timer.js'
import videos from './video-embeds.js'
import getName from './get-name.js'
import viewAnimation from './view-animation.js'
import getCurrentMonth from './month.js'
import getCurrentYear from './year.js' 
import kkThankYou from './thank-you.js'
import { exitIntent, initModals } from './modal.js'

contentLoaded()

window.contentLoaded(window, function (e) {
	polyfill()
	kkThankYou();
	phoneConcat()
	images()
	getCurrentMonth()
	sliders()
	smoothScroll()
	stickyHeader()
	videos()
	viewAnimation()
	getCurrentYear()
	tabs()
	initModals()
	multistep()
	getName()
	scrollTabs()

	//accordion()
	//timer()


	// enable for multistep form
	// use jquery ready() function instead of contentLoaded if using multistep form
	// jQuery(document).ready(function($){ // functions to run });
	//multistep()

	// lightbox init
	function initFancybox() {
		jQuery('a.lightbox, [data-fancybox]').fancybox({
			parentEl: 'body',
			margin: [50, 0]
		});
		
	}
})
