export default function getName() {
	$('.get-name').on('keydown, keyup', function () {
	  //get a reference to the text input value
	  var texInputValue = $('#fullname').val();
	  
	  //show the text input value in the UI
	  $('#name-input').html(texInputValue); 
	});

}
